<template>
  <div id="story-list" class="story__container">
    <!-- BANNER -->
    <template v-for="item in storyListTop" :key="item.ID">
      <banner-single>
        <template #image>
          <div
            class="bannerbox__image d-none d-sm-block"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
          <div
            class="bannerbox__image d-block d-sm-none"
            :style="{ backgroundImage: 'url(' + item.Img + ')' }"
          ></div>
        </template>
        <template #operate>
          <div class="text-start">
            <div class="col-lg-6 banner__caption">
              <span class="bannerbox__type"><span class="deco-border"></span>{{ $filters.removeHtmlTag(item.HciStoryClass) }}</span>
              <h2 class="bannerbox__title border-bottom">{{ $filters.removeHtmlTag(item.Title) }}</h2>
              <p>{{ item.SubTitle }}</p>
              <router-link
                :to="'/' + this.$route.params.lang + `/story/${item.SeoUrl}`"
                @click="clickRouterTop"
                class="
                  btn btn-pill btn-light hover-green
                  w-100
                  d-flex
                  justify-content-center
                "
                >了解更多<span class="icon-arrow-right"><span></span><span></span></span></router-link>
            </div>
          </div>
        </template>
        <template #mark>
          <img
            src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
            class="img-fluid banner__mark"
          />
        </template>
      </banner-single>
    </template>

    <!-- MAIN -->
    <article class="bg-secondary">
      <nav class="container">
        <div class="row">
          <div class="col-md-5 col-lg-6"></div>
          <div class="col-md-7 col-lg-6">
            <span></span>
            <div class="searchbar-wrap">
              <h2 class="searchbar__title">故事類型</h2>

              <div class="select__wrap">
                <div class="select__bar" @click="isOpen = !isOpen">{{ showValue }}</div>
                <div class="select__options" v-if="isOpen">
                  <button v-for="(item, index) in storyClass" :key="index" @click="sctClass($event), (isOpen = !isOpen)" :value="item">{{ item }}</button>
                </div>
              </div>

              <!-- <select
                class="searchbar__select form-select"
                @change="sctClass($event)"
              >
                <option :value="item" v-for="item in storyClass" :key="item.ID">
                  {{ item }}
                </option>
              </select> -->

            </div>
          </div>
        </div>
      </nav>

      <!-- SECTION_1 -->
      <card-fancy :storyList="storyList" :storyListLength="storyListLength"></card-fancy>
      <div class="bottom-resize"></div>
    </article>
  </div>
</template>

<script>
import axios from "axios";

import BannerSingle from "@/components/BannerSingle.vue";
import CardFancy from "@/components/CardFancy.vue";

export default {
  components: {
    BannerSingle,
    CardFancy,
  },
  data() {
    return {
      seoData: null,
      sTitle: null,
      sDescription: null,
      sKeywords: null,
      sSiteName: null,
      sImage: null,
      sType: null,
      currentURL: null,
      isOpen: false,
      showValue: "請選擇分類",
      pagesBanner: [],
      storyClass: [],
      storyList: [],
      storyListTop: [],
      storyListTopID: 0,
      storyListAll: [],
      storyListFilted: [],
      storyListLength: null,
    };
  },
  created() {
    var seoFD = new FormData();
    seoFD.append("mode", "raw_sct");
    seoFD.append("data", '{"class":"HciSEO","lang":"zh-TW"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: seoFD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      const currtUrlName = this.$route.name.toLowerCase();
      this.seoData = res.data.data.filter(item => (item.SetPage == currtUrlName));
      this.sTitle = this.seoData[0].title;
      this.sDescription= this.seoData[0].description;
      this.sKeywords = this.seoData[0].keywords;
      this.sSiteName = this.seoData[0].site_name;
      this.sImage = this.seoData[0].image;
      this.sType = this.seoData[0].type;
      this.currentURL = window.location.href;
      
      console.log("this.currentURL=====>", this.currentURL)

      const siteTitle = document.querySelector("title");
      siteTitle.innerText = this.sTitle;

      const seoDescriptionD = document.querySelector("meta[name='description']");
      seoDescriptionD.setAttribute('content', this.sDescription);

      const seoTitle = document.querySelector("meta[property='og:title']");
      seoTitle.setAttribute('content', this.sSiteName);

      const seoDescription = document.querySelector("meta[property='og:description']");
      seoDescription.setAttribute('content', this.sDescription);

      const seoSiteName = document.querySelector("meta[property='og:site_name']");
      seoSiteName.setAttribute('content', this.sSiteName);

      const seoImg = document.querySelector("meta[property='og:image']");
      seoImg.setAttribute('content', this.sImage);

      const seoType = document.querySelector("meta[property='og:type']");
      seoType.setAttribute('content', this.sType);

      const seoKeywords = document.querySelector("meta[name='keywords']");
      seoKeywords.setAttribute('content', this.sKeywords);

      const seoURL = document.querySelector("meta[property='og:url']");
      seoURL.setAttribute('content', this.currentURL);
    });

    var FD = new FormData();
    FD.append("mode", "raw_sct");
    FD.append("data", '{"class":"HciStory","lang":"zh-TW","sw":"1"}');
    axios({
      method: "post",
      url: "https://nkg-web-api.japaneast.cloudapp.azure.com/Form/",
      data: FD,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      this.storyListLength = res.data.data.length
      this.storyList = res.data.data;
      this.storyClass = res.data.HciStoryClass;
      this.storyListAll = this.storyList;

      this.storyListTop = this.storyList.filter((item) => item.TopSW == "1");
      this.storyList = this.storyList.filter(
        (item) => item.ID !== this.storyListTop[0].ID
      );
      this.storyListTopID = this.storyListTop[0].ID;
      // var sizes = [2,2],i = 0,j = 0,chunks = [];
      // while (i < Object.keys(res.data.data).length){
      //   chunks.push(res.data.data.slice(i, i += sizes[j++ % sizes.length]));
      // }
      // this.storyList=chunks;

      var sizes = [2, 2],
        i = 0,
        j = 0,
        chunks = [];
      while (i < Object.keys(this.storyList).length) {
        chunks.push(this.storyList.slice(i, (i += sizes[j++ % sizes.length])));
      }
      this.storyList = chunks;
    });
  },
  methods: {
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    },
    sctClass(e) {
      console.log("CK->", e.target.value);
      this.showValue = e.target.value;
      if (e.target.value == "全部") {
        this.storyList = this.storyListAll.filter(
          (item) => item.ID !== this.storyListTopID
        );
      } else {
        this.storyList = this.storyListAll.filter(
          (item) =>
            this.$filters.removeHtmlTag(item.HciStoryClass) == e.target.value &&
            item.ID !== this.storyListTopID
        );
      }
      var sizes = [2, 2],
        i = 0,
        j = 0,
        chunks = [];
      while (i < Object.keys(this.storyList).length) {
        chunks.push(this.storyList.slice(i, (i += sizes[j++ % sizes.length])));
      }
      this.storyList = chunks;
    },
  },
};
</script>

<style>
.story__container .sec:last-child .sec-deco-bottom {
  display: none !important;
}
.bottom-resize {
  height: 8rem;
  @media (max-width:576px) {
    height: 3rem;
  }
}
</style>
